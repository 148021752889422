import React from "react";

const Sonata = ({ width = 100 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 59.53 21.43"
    width={width}
  >
    <path d="M6,20.29a1,1,0,0,1-1.08,1.08H1.09a1.06,1.06,0,0,1-.78-.3A1,1,0,0,1,0,20.29V16.41H1.36v3.76h3.3V16.53L.44,11.43A1.79,1.79,0,0,1,0,10.27V7.17a1,1,0,0,1,.31-.78,1.06,1.06,0,0,1,.78-.3H4.94A1,1,0,0,1,6,7.17v3.56H4.66V7.29H1.36v3.22l4.24,5.1A1.75,1.75,0,0,1,6,16.75Z" />
    <path d="M13.72,20.27a1,1,0,0,1-1.1,1.1H9.36a1,1,0,0,1-1.1-1.1V13a1,1,0,0,1,1.1-1.1h3.26a1,1,0,0,1,1.1,1.1Zm-1.3,0V13H9.56v7.22Z" />
    <path d="M21.3,21.37H20V13l-2.86.08v8.26h-1.3v-9.5h1.3v.26l1.53-.2a12.69,12.69,0,0,1,1.54-.12,1,1,0,0,1,1.09,1.06Z" />
    <path d="M28.77,21.37H27.49v-.26l-1.54.2a13,13,0,0,1-1.54.12.93.93,0,0,1-1.06-1.06V16.91a1,1,0,0,1,1.1-1.1h3.06V13H24.72v1.62H23.46V13a1,1,0,0,1,1.09-1.1h3.13A1,1,0,0,1,28.77,13Zm-1.26-1.18V16.83h-2.9v3.42Z" />
    <path d="M34.32,21.37H32.47a1,1,0,0,1-1.09-1.1V13H30.23V11.87h1.15v-3h1.28v3h1.66V13H32.66v7.22h1.66Z" />
    <path d="M41.35,21.37H40.07v-.26l-1.54.2a13,13,0,0,1-1.54.12.93.93,0,0,1-1.06-1.06V16.91a1,1,0,0,1,1.1-1.1h3.06V13h-2.8v1.62H36V13a1,1,0,0,1,1.09-1.1h3.12a1,1,0,0,1,1.1,1.1Zm-1.26-1.18V16.83h-2.9v3.42Z" />
    <path d="M44.25,6.4V5H51.6V1.36H44.25V0H59.53V1.36H52.82V5h6.71V6.4Z" />
    <path d="M44.25,14.05V12.77h.27c-.08-.51-.14-1-.21-1.54a13,13,0,0,1-.12-1.53.94.94,0,0,1,1.06-1.07h3.46a1,1,0,0,1,1.1,1.11v3h2.86V10H51.05V8.74h1.6a1,1,0,0,1,1.1,1.09V13a1,1,0,0,1-1.1,1.09Zm1.18-1.26h3.36V9.89H45.37Z" />
    <path d="M44.25,17.54V16.26H59.53v1.28Z" />
    <path d="M44.25,21.1V19.82H59.53V21.1Z" />
    <line
      className="a"
      x1="42.99"
      y1="0.62"
      x2="0.15"
      y2="0.62"
      stroke={"#000"}
      strokeMiterlimit={10}
    />
  </svg>
);

export default Sonata;
