import React from "react";

const Save = ({ width = 30 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={width}>
    <path
      d="M506.513,124.12L387.879,5.486c-0.001-0.001-0.003-0.003-0.005-0.004C384.467,2.076,379.697,0,374.634,0
			c-15.018,0-341.6,0-355.902,0C8.387,0,0,8.387,0,18.732v474.537C0,503.613,8.387,512,18.732,512c22.563,0,452.353,0,474.537,0
			c10.345,0,18.732-8.387,18.732-18.732V137.366C512,132.398,510.027,127.634,506.513,124.12z M156.098,37.463h199.805v110.823
			H156.098V37.463z M400.45,474.537h-288.9V316.935h288.9V474.537z M474.537,474.537h-36.623V298.204
			c0-10.345-8.387-18.732-18.732-18.732H92.818c-10.345,0-18.732,8.387-18.732,18.732v176.333H37.463V37.463h81.171v129.555
			c0,10.345,8.387,18.732,18.732,18.732h237.268c10.345,0,18.732-8.387,18.732-18.732V63.954l81.171,81.171V474.537z"
    />
    <path
      d="M309.514,55.015c-10.345,0-18.732,8.387-18.732,18.732v38.256c0,10.346,8.386,18.732,18.732,18.732
			c10.345,0,18.732-8.387,18.732-18.732V73.747C328.246,63.402,319.859,55.015,309.514,55.015z"
    />
  </svg>
);

export default Save;
