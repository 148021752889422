import React from "react";

const Concordea = ({ width = 180 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66.25 17.1"
    width={width}
  >
    <path d="M32.83,2c16.88,0,30.79,3.48,32.61,8a2.53,2.53,0,0,0,.21-1C65.65,4,51,0,32.83,0S0,4,0,9a2.72,2.72,0,0,0,.21,1C2,5.48,15.94,2,32.83,2Z" />
    <path d="M7.54,16.71a5.2,5.2,0,0,1-2.13.38,3.78,3.78,0,0,1-4-4.09A4,4,0,0,1,5.63,8.75a4.18,4.18,0,0,1,1.92.37L7.3,10a3.66,3.66,0,0,0-1.63-.34A3,3,0,0,0,2.52,13a2.94,2.94,0,0,0,3.1,3.25,4.27,4.27,0,0,0,1.71-.33Z" />
    <path d="M15.51,12.84c0,2.79-1.69,4.26-3.75,4.26S8.12,15.45,8.12,13s1.6-4.25,3.75-4.25S15.51,10.45,15.51,12.84ZM9.24,13c0,1.72.93,3.27,2.58,3.27s2.59-1.52,2.59-3.36c0-1.6-.84-3.28-2.58-3.28S9.24,11.2,9.24,13Z" />
    <path d="M16.87,17V8.89H18L20.6,13a22.21,22.21,0,0,1,1.45,2.62h0C22,14.51,22,13.53,22,12.27V8.89h1V17H21.88l-2.56-4.1a24.7,24.7,0,0,1-1.52-2.7h0c.06,1,.08,2,.08,3.34V17Z" />
    <path d="M30.41,16.71a5.2,5.2,0,0,1-2.13.38,3.77,3.77,0,0,1-4-4.09,4,4,0,0,1,4.22-4.25,4.25,4.25,0,0,1,1.92.37l-.26.85a3.66,3.66,0,0,0-1.63-.34A3,3,0,0,0,25.39,13a2.94,2.94,0,0,0,3.11,3.25,4.25,4.25,0,0,0,1.7-.33Z" />
    <path d="M38.38,12.84c0,2.79-1.69,4.26-3.75,4.26S31,15.45,31,13s1.6-4.25,3.76-4.25S38.38,10.45,38.38,12.84ZM32.11,13c0,1.72.93,3.27,2.58,3.27s2.59-1.52,2.59-3.36c0-1.6-.84-3.28-2.58-3.28S32.11,11.2,32.11,13Z" />
    <path d="M39.74,9a11.65,11.65,0,0,1,2-.16,3.28,3.28,0,0,1,2.34.66A2,2,0,0,1,44.72,11a2.11,2.11,0,0,1-1.52,2.06v0a2,2,0,0,1,1.18,1.63A13,13,0,0,0,45,17H43.93A10,10,0,0,1,43.39,15c-.24-1.12-.68-1.54-1.62-1.57h-1V17h-1Zm1,3.69h1.06c1.12,0,1.82-.61,1.82-1.54s-.75-1.5-1.85-1.51a4,4,0,0,0-1,.1Z" />
    <path d="M46.2,9a15.81,15.81,0,0,1,2.22-.16,4.64,4.64,0,0,1,3.27,1,3.74,3.74,0,0,1,1.14,2.91,4.28,4.28,0,0,1-1.16,3.14A5,5,0,0,1,48.09,17,16.84,16.84,0,0,1,46.2,17Zm1,7.15a6.32,6.32,0,0,0,1,.06,3.11,3.11,0,0,0,3.45-3.43c0-1.9-1.07-3.12-3.28-3.12a5.89,5.89,0,0,0-1.22.11Z" />
    <path d="M58.37,13.18H55.23v2.91h3.5V17H54.19V8.89h4.36v.87H55.23v2.56h3.14Z" />
    <path d="M61.4,14.43,60.56,17H59.48l2.75-8.08h1.26L66.25,17H65.13l-.86-2.54Zm2.65-.82-.79-2.32c-.18-.53-.3-1-.42-1.48h0c-.11.48-.25,1-.4,1.46l-.79,2.34Z" />
  </svg>
);

export default Concordea;
