export const URL_HOME = "/";
export const URL_TICKETS = "/tickets/";
export const URL_CONTACT = "/contact/";
export const URL_VISIT = "/visit-us/";
export const URL_LOGIN = "/login/";
export const URL_PROFILE = "/my-account/";
export const URL_CART = "/cart/";
export const URL_ADMIN = "/admin/";
export const URL_EVENT = "/event/";
export const URL_RESET_PASSWORD = "/reset-password/";
