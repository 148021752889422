import React from "react";

const Isaak = ({ width = 170 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80.16 30.17"
    width={width}
  >
    <path d="M44,4.81l.43-.1a1.54,1.54,0,0,1,.41-.08c.09,0,.13,0,.13.09s-.46.27-1.38.49a27.35,27.35,0,0,1-3.3,3,1,1,0,0,1-.56.22c-.23,0-.34-.1-.34-.31s.17-.39.53-.78.59-.59.69-.59a.07.07,0,0,1,.08.08,2.51,2.51,0,0,1-.35.43,4.21,4.21,0,0,0-.41.5h0a17.31,17.31,0,0,0,2.58-2.33,6.49,6.49,0,0,1-1.28.11c-.64,0-1-.12-1.24-.38a1.24,1.24,0,0,1-.29-.68A2.74,2.74,0,0,1,41,2.57,11.56,11.56,0,0,1,43.65,1,6.57,6.57,0,0,1,46,.39c.56,0,.85.19.85.57Q46.87,2,44,4.81Zm2.42-4c0-.11-.07-.16-.22-.16a6.73,6.73,0,0,0-1.92.65A12.3,12.3,0,0,0,41.45,3c-.9.71-1.35,1.23-1.35,1.55s.46.48,1.37.48A5,5,0,0,0,43,4.93s.48-.45,1.3-1.29c.42-.43.84-.89,1.24-1.38A3.55,3.55,0,0,0,46.44.82Z" />
    <path d="M51.57,5.16a2.13,2.13,0,0,1-.87,1.38,6.32,6.32,0,0,1-2.2,1.3A7.5,7.5,0,0,1,46,8.27c-1.17,0-1.75-.35-1.75-1,0-.42.33-.8,1-1.15a3.47,3.47,0,0,1,1.57-.45c.15,0,.22,0,.22.13s-.07.11-.23.13a4.92,4.92,0,0,0-1.29.39c-.59.25-.88.54-.88.86s.45.59,1.36.59a7.11,7.11,0,0,0,2.37-.45,6.56,6.56,0,0,0,2.12-1.15c.54-.45.8-.8.8-1s-.36-.38-1.08-.47c-1.2-.14-1.87-.22-2-.26-.73-.17-1.09-.49-1.09-.93s.43-1.12,1.27-1.63A10.49,10.49,0,0,1,51,.74,8.92,8.92,0,0,1,53.25.36c.73,0,1.09.22,1.09.67s-.34.84-1,1.36a2.87,2.87,0,0,1-1.59.76c-.33,0-.5-.12-.5-.34s.19-.42.57-.68a1.73,1.73,0,0,1,.84-.36c.07,0,.11,0,.11.06l-.57.31c-.38.21-.57.36-.59.46a.12.12,0,0,0,.09,0A3,3,0,0,0,53,2.1c.65-.37,1-.68,1-.92S53.71.86,53.2.86a8.91,8.91,0,0,0-2.49.51,15.49,15.49,0,0,0-1.9.73c-.94.44-1.41.84-1.41,1.19s.3.4.89.51L50.06,4a2.14,2.14,0,0,1,1.27.45A1.17,1.17,0,0,1,51.57,5.16Z" />
    <path d="M59.53,4.89H60c.25,0,.37.1.37.23s-.09.12-.27.12l-.35,0h-.52L58.14,6.65a8.29,8.29,0,0,1-1.26,1.42c-.08,0-.14-.05-.2-.16a.45.45,0,0,1-.09-.25,18.5,18.5,0,0,1,1.88-2.44H58c-.6,0-1.49,0-2.68.1L53.5,6.91l-.72.65a2.3,2.3,0,0,1-.71.51.14.14,0,0,1-.15-.16q0-.2,1.59-1.53l1.26-1a11.08,11.08,0,0,0-2.61.26h0c0,.07.26.11.79.11h.73c.1,0,.15,0,.15.06s-.4.16-1.2.16-1-.09-1-.26.2-.37.6-.45a6.44,6.44,0,0,1,.85-.09L55.21,5q1.3-1.08,3.72-2.79C61,.73,62.21,0,62.61,0c.19,0,.29.13.29.39a3.23,3.23,0,0,1-.77,1.29Zm-.79,0,2.58-3.14.48-.58a3.56,3.56,0,0,0,.41-.6v0c0-.05,0-.07-.06-.07a22.57,22.57,0,0,0-3.28,2.21l-3,2.25Z" />
    <path d="M68.09,4.89h.51c.24,0,.37.1.37.23s-.09.12-.27.12l-.36,0h-.52L66.7,6.65a8.29,8.29,0,0,1-1.26,1.42c-.07,0-.14-.05-.2-.16a.52.52,0,0,1-.08-.25A17.78,17.78,0,0,1,67,5.22H66.6c-.6,0-1.49,0-2.67.1L62.07,6.91l-.73.65c-.38.34-.62.51-.71.51a.14.14,0,0,1-.15-.16q0-.2,1.59-1.53l1.26-1a11.29,11.29,0,0,0-2.61.26h0c0,.07.26.11.79.11h.74c.1,0,.15,0,.15.06s-.4.16-1.2.16-1-.09-1-.26.2-.37.61-.45a6,6,0,0,1,.85-.09L63.77,5q1.3-1.08,3.73-2.79C69.55.73,70.77,0,71.17,0q.3,0,.3.39a3.18,3.18,0,0,1-.78,1.29Zm-.79,0,2.59-3.14.48-.58a3.36,3.36,0,0,0,.4-.6v0c0-.05,0-.07,0-.07a21.37,21.37,0,0,0-3.28,2.21l-3,2.25Z" />
    <path d="M72.91,4.77l.21,1.48a6.93,6.93,0,0,0,.3,1.42.48.48,0,0,0,.3.18c.13,0,.19.06.19.09s-.08.07-.25.09a1.45,1.45,0,0,1-.32,0,.56.56,0,0,1-.53-.34,26.76,26.76,0,0,1-.58-3.08c-1.11,1.19-2,2.22-2.78,3.09-.19.22-.32.33-.4.33a.34.34,0,0,1-.3-.25,23.83,23.83,0,0,1,3-3.5L74.63,1.1v0a4.84,4.84,0,0,0-1.23.74l-1,.74c-.06,0-.09,0-.09-.11a5.43,5.43,0,0,1,1.33-1,5.67,5.67,0,0,1,1.63-.9.48.48,0,0,1,.26.13.35.35,0,0,1,.14.22,2.35,2.35,0,0,1-.42.51l-.51.53L72.59,4.27h.09c.09,0,.15.06.16.19l5.27-2.76a10.92,10.92,0,0,1,2-.9c.07,0,.1.06.1.17s-.15.34-.45.48l-1,.44Z" />
    <path d="M10.33,23.11c.3,0,.46.18.46.45s-.12.42-.36.42a.12.12,0,0,1-.11-.05.17.17,0,0,1,0-.1.34.34,0,0,0,0-.11c0-.13-.14-.19-.42-.17a57.29,57.29,0,0,1-4.1,4q-3,2.59-4.84,2.6a1,1,0,0,1-.7-.27A.88.88,0,0,1,0,29.21c0-.63.52-1.41,1.57-2.34a16.53,16.53,0,0,1,3.49-2.32A15,15,0,0,1,9,23.13a58.06,58.06,0,0,0,3.88-4.62,25.19,25.19,0,0,0,3.29-4.84,2.46,2.46,0,0,0,.19-.8c0-.23-.11-.35-.31-.35a12.31,12.31,0,0,0-4,1.09,17.69,17.69,0,0,0-5,3C5.6,17.94,4.84,19.06,4.84,20c0,.75.39,1.12,1.18,1.12a8.61,8.61,0,0,0,4-1.27,5.73,5.73,0,0,0,2.65-2.32.44.44,0,0,1,.12-.16c.15,0,.22.12.22.36s-.34.84-1,1.48a10,10,0,0,1-2.67,1.66,7.63,7.63,0,0,1-3,.8A2,2,0,0,1,4.75,21a2.18,2.18,0,0,1-.61-1.55c0-1.16.76-2.37,2.27-3.62a18.92,18.92,0,0,1,4.82-2.79A12.49,12.49,0,0,1,15.61,12c.93,0,1.4.37,1.4,1.11Q17,15.23,10.33,23.11Zm-1.78.5a15.74,15.74,0,0,0-3.84,1.67,15.56,15.56,0,0,0-3.36,2.48c-.67.69-1,1.21-1,1.55s.19.46.58.46A2.5,2.5,0,0,0,2,29.48a19.23,19.23,0,0,0,3.32-2.7C6.53,25.65,7.6,24.6,8.55,23.61Z" />
    <path d="M26.56,12.26A1.6,1.6,0,0,1,28.27,14c0,1.5-.84,3.36-2.52,5.6a21.23,21.23,0,0,1-4.42,4.53,5.1,5.1,0,0,1-2.94,1.11,2.1,2.1,0,0,1-2.33-2.41,9,9,0,0,1,1.72-4.92A15.67,15.67,0,0,1,21.41,14,9.32,9.32,0,0,1,24,12.38q.45,0,.45.54a4.58,4.58,0,0,1,1.64-.65c.05-.33.09-.49.14-.49Q26.64,11.78,26.56,12.26Zm-.09.45a9.77,9.77,0,0,1-1.2,2.37c-2,3-3.5,4.45-4.55,4.45q-.72,0-.72-1c0-1.5,1.21-3.19,3.61-5.06a14.84,14.84,0,0,0-2.49,1.89,16.91,16.91,0,0,0-2.84,3.69,8.86,8.86,0,0,0-1.43,4.33,1.48,1.48,0,0,0,.29,1,1.07,1.07,0,0,0,.88.4q1.49,0,3.9-2.27a25.2,25.2,0,0,0,3.94-4.74c1.2-1.83,1.8-3.2,1.8-4.1a.86.86,0,0,0-1-1A.52.52,0,0,0,26.47,12.71Zm-.51.14a8.41,8.41,0,0,0-2.59,1.78,11,11,0,0,0-2,2.43,4.31,4.31,0,0,0-.69,1.67q0,.33.3.33c.57,0,1.5-.87,2.79-2.6A17.4,17.4,0,0,0,26,12.85Z" />
    <path d="M32.1,19.65l-2.42,2.89A23.83,23.83,0,0,1,27.47,25a.58.58,0,0,1-.32-.19c-.14-.12-.2-.22-.2-.31s1.3-1.86,3.91-4.94c-.76-.12-1.14-.29-1.14-.52s.16-.34.48-.33l1.37.07,3-3.29c.48-.55,1.21-1.33,2.19-2.32a25.66,25.66,0,0,0-6.27,1.71.87.87,0,0,1-.32.09c-.21,0-.31-.13-.31-.39,0-.5,1.05-1,3.15-1.61a20.79,20.79,0,0,1,5.17-.84,4.94,4.94,0,0,1,2.17.42,1.81,1.81,0,0,1,1.14,1.72q0,2.58-3.56,4.14A14.5,14.5,0,0,1,32.1,19.65Zm5.44-6.55a.46.46,0,0,1,.05.21A15.48,15.48,0,0,1,35.4,16L33,18.72A16.42,16.42,0,0,0,36.84,18a7.62,7.62,0,0,0,3.39-1.91,2.77,2.77,0,0,0,.88-1.73c0-.87-.85-1.3-2.55-1.3C38.31,13.05,38,13.07,37.54,13.1Z" />
    <path d="M41.31,23.18a14.88,14.88,0,0,0,4.36,1.24,2.29,2.29,0,0,0,.36-.05,2.2,2.2,0,0,1,.35-.05.07.07,0,0,1,.08.08c0,.19-.19.37-.56.52a2.44,2.44,0,0,1-.83.19,15.13,15.13,0,0,1-4.43-1.26,5.88,5.88,0,0,1-4,1.78,3,3,0,0,1-1.37-.29,1.22,1.22,0,0,1-.71-1.13,1.76,1.76,0,0,1,1.11-1.52,4.15,4.15,0,0,1,1.95-.49,7,7,0,0,1,2.68.59l4.76-5.08c1.32-1.43,2.28-2.4,2.88-2.93,1.44-1.25,2.6-1.88,3.49-1.88s1.19.33,1.27,1a2.77,2.77,0,0,1,.61-.17c.08,0,.12,0,.12.16s-.1.17-.29.25l-.4.16a7.3,7.3,0,0,1-2,4c-1.28,1.52-2.28,2.27-3,2.27-.51,0-.76-.23-.76-.69a5.22,5.22,0,0,1,1.44-2.79,11.46,11.46,0,0,1,3.69-3,1.27,1.27,0,0,0,0-.23q0-.54-.48-.54c-.59,0-1.55.63-2.88,1.9-.5.47-1.34,1.35-2.53,2.63l-3,3.25Zm-1.72.32a7.63,7.63,0,0,0-2.66-.5c-1.31,0-2,.4-2,1.18s.48,1,1.45,1A4.76,4.76,0,0,0,39.59,23.5Zm12.47-8.93a10.17,10.17,0,0,0-3.23,2.61,5.07,5.07,0,0,0-1.44,2.51c0,.19.09.28.26.28.41,0,1.12-.56,2.13-1.67A11.2,11.2,0,0,0,52.06,14.57Z" />
    <path d="M59.12,19.56l.71-.16a3.08,3.08,0,0,1,.68-.13c.15,0,.23.05.23.15s-.77.45-2.3.82a46.38,46.38,0,0,1-5.51,5,1.77,1.77,0,0,1-.93.36q-.57,0-.57-.51c0-.21.29-.65.89-1.3s1-1,1.14-1,.13.05.13.14a3.49,3.49,0,0,1-.57.71,8.41,8.41,0,0,0-.69.83h0c.24,0,1.67-1.3,4.31-3.88a12,12,0,0,1-2.14.18c-1.06,0-1.75-.21-2.06-.64A2,2,0,0,1,52,19q0-1.47,2.1-3.18a18.72,18.72,0,0,1,4.44-2.57,11,11,0,0,1,3.94-1.07q1.41,0,1.41,1Q63.86,14.83,59.12,19.56Zm4-6.65q0-.27-.36-.27a11.69,11.69,0,0,0-3.2,1.09,21,21,0,0,0-4.76,2.87c-1.5,1.18-2.25,2-2.25,2.58s.77.81,2.29.81a7.82,7.82,0,0,0,2.58-.23c.09,0,.81-.75,2.17-2.14.7-.71,1.39-1.48,2.06-2.29A5.85,5.85,0,0,0,63.15,12.91Z" />
    <path d="M67.21,18.47q3.15-2.55,4.35-3.4Q75,12.63,76.4,12.63a1.11,1.11,0,0,1,.72.25.83.83,0,0,1,.31.66c0,.75-.89,2-2.67,3.75L71,20.9C69,23,67.91,24.14,67.91,24.4a.27.27,0,0,0,.21.29h.38c.22,0,.33,0,.33.14s-.15.19-.46.27a2.31,2.31,0,0,1-.6.1,1,1,0,0,1-.63-.21.66.66,0,0,1-.26-.56,1.82,1.82,0,0,1,.39-1,37.89,37.89,0,0,1,2.59-2.78l4.36-4.34c.47-.46.83-.81,1.06-1.05a7.5,7.5,0,0,0,1-1.15,2.23,2.23,0,0,0,.37-.93c0-.13-.06-.2-.18-.2a9.08,9.08,0,0,0-2.8,1.36A56.7,56.7,0,0,0,62,24.24c-.48.51-.75.76-.84.76a.47.47,0,0,1-.3-.17.45.45,0,0,1-.18-.3c0-.26.81-1.24,2.44-2.92l4.53-4.75q2.44-2.71,2.44-3.55c0-.18-.07-.27-.23-.27-.66,0-2.23,1.06-4.69,3.17a26.56,26.56,0,0,0-2.92,2.87c-.14.16-.24.23-.31.23a.3.3,0,0,1-.35-.35q0-.43,2-2.37a26.09,26.09,0,0,1,2.92-2.4,6.59,6.59,0,0,1,3.31-1.53c.51,0,.76.28.76.82a5.37,5.37,0,0,1-1.37,2.7A22.83,22.83,0,0,1,67.21,18.47Z" />
  </svg>
);

export default Isaak;
