export const USER = {
  LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USER_LOGIN_FAILURE",

  LOGOUT: "USER_LOGOUT",
  UPDATE: "USER_UPDATE",

  GET_REQUEST: "USER_GET_REQUEST",
  GET_SUCCESS: "USER_GET_SUCCESS",
  GET_FAILURE: "USER_GET_FAILURE",

  PATCH_REQUEST: "USER_PATCH_REQUEST",
  PATCH_SUCCESS: "USER_PATCH_SUCCESS",
  PATCH_FAILURE: "USER_PATCH_FAILURE",

  DELETE_REQUEST: "USER_DELETE_REQUEST",
  DELETE_SUCCESS: "USER_DELETE_SUCCESS",
  DELETE_FAILURE: "USER_DELETE_FAILURE",

  REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USER_REGISTER_FAILURE",

  RESET_PASSWORD_REQUEST: "USER_RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "USER_RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "USER_RESET_PASSWORD_FAILURE",

  TOKEN_LOGIN_REQUEST: "USER_TOKEN_LOGIN_REQUEST",
  TOKEN_LOGIN_SUCCESS: "USER_TOKEN_LOGIN_SUCCESS",
  TOKEN_LOGIN_FAILURE: "USER_TOKEN_LOGIN_FAILURE",

  RESET_RESULTS: "USER_RESET_RESULTS",
};

export const EVENT = {
  GET_REQUEST: "EVENT_GET_REQUEST",
  GET_SUCCESS: "EVENT_GET_SUCCESS",
  GET_FAILURE: "EVENT_GET_FAILURE",

  GET_ALL_REQUEST: "EVENT_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "EVENT_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "EVENT_GET_ALL_FAILURE",

  UPDATE: "EVENT_UPDATE",

  PATCH_REQUEST: "EVENT_PATCH_REQUEST",
  PATCH_SUCCESS: "EVENT_PATCH_SUCCESS",
  PATCH_FAILURE: "EVENT_PATCH_FAILURE",

  DELETE_REQUEST: "EVENT_DELETE_REQUEST",
  DELETE_SUCCESS: "EVENT_DELETE_SUCCESS",
  DELETE_FAILURE: "EVENT_DELETE_FAILURE",

  CREATE_REQUEST: "EVENT_CREATE_REQUEST",
  CREATE_SUCCESS: "EVENT_CREATE_SUCCESS",
  CREATE_FAILURE: "EVENT_CREATE_FAILURE",

  GET_TICKET_REQUEST: "EVENT_GET_TICKET_REQUEST",
  GET_TICKET_SUCCESS: "EVENT_GET_TICKET_SUCCESS",
  GET_TICKET_FAILURE: "EVENT_GET_TICKET_FAILURE",
};

export const CART = {
  ADD_ITEM: "CART_ADD_ITEM",
  REMOVE_ITEM: "CART_REMOVE_ITEM",

  BUY_REQUEST: "CART_BUY_REQUEST",
  BUY_SUCCESS: "CART_BUY_SUCCESS",
  BUY_FAILURE: "CART_BUY_FAILURE",

  RESET_RESULTS: "CART_RESET_RESULTS",
};
