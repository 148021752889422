import React from "react";

const Mozell = ({ width = 100 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 43.83 23.06"
    width={width}
  >
    <path d="M6.79,5.92c0-1.82,0-3.61.09-4.65h0C6.51,2.84,5.4,6.6,4.42,10.06h-1C2.73,7.17,1.59,2.87,1.23,1.22h0c.05,1.14.07,3.2.05,4.89l0,3.93h-1L.28.09l1.65,0c.86,3.22,1.88,7,2.07,8H4C4.35,6.79,5.44,3.16,6.29.15H8l-.09,9.93H6.75Z" />
    <path d="M16.78,5.18c0,2.79-1.36,5.19-3.74,5.17S9.42,8,9.45,5.11C9.47,2.36,10.92,0,13.18,0S16.8,2.43,16.78,5.18Zm-6.12-.09c0,2.07.82,3.9,2.45,3.92s2.44-1.79,2.46-3.81-.8-3.84-2.46-3.86S10.68,3.09,10.66,5.09Z" />
    <path d="M17.44,9l4.74-7.36-4.46,0,0-1.34,5.93.06V1.58L19,8.86l4.9,0-.17,1.34-6.27-.06Z" />
    <path d="M30.27,5.78l-4.09,0,0,3.16,4.52,0-.16,1.36L25,10.25,25.09.32l5.45,0V1.73l-4.31,0,0,2.69,4.09,0Z" />
    <path d="M32.15.38h1.14L33.21,9l4.11,0-.17,1.37-5.09,0Z" />
    <path d="M38.45.44H39.6L39.52,9l4.11,0-.18,1.37-5.09-.05Z" />
    <path d="M37.36,12.92l3,9.93H39.08l-1.49-5.16c-.55-1.92-.78-2.83-.91-3.41h0c-.11.51-.38,1.41-.88,3.28l-1.56,5.29H33l3-9.93Z" />
    <path d="M26.76,17.44h4.09V14.28H26.33l.15-1.36H32v9.93H26.55V21.49h4.3V18.8H26.76Z" />
    <path d="M24.94,12.92v9.93H23.57C21.92,19.47,20,16,19.63,14.93h0c.06,1.23.06,2.87.06,4.49v3.43H18.6V12.92h1.29c.94,1.86,3.54,7.12,4,8.27h0c-.06-1.26-.06-2.67-.06-4.43V12.92Z" />
    <path d="M15.48,22.85V12.92h1.15v9.93Z" />
    <path d="M7,15.89c.38-1.72,1.27-3.18,3.3-3.18,2.34,0,3.58,2.34,3.58,5.16s-1.37,5.19-3.64,5.19c-2,0-3-1.58-3.26-3.21H8.18c.27.93.78,1.87,2.1,1.87,1.52,0,2.42-1.61,2.42-3.79S11.82,14,10.27,14c-1.23,0-1.73.75-2.08,1.85Z" />
    <path d="M.43,17.44H4.52V14.28H0l.15-1.36H5.67v9.93H.21V21.49H4.52V18.8H.43Z" />
    <rect x="41.41" y="20.25" width="2.41" height="2.41" />
  </svg>
);

export default Mozell;
