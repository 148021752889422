import React from "react";

const Arrangiatore = ({ width = 200 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.09 20" width={width}>
    <path d="M10.5,15.3,9,11.16H3L1.45,15.3H0l5.33-14H6.64l5.3,14ZM6.23,3.75l-.14-.43A3.63,3.63,0,0,1,6,2.76h0c0,.21-.09.4-.13.56s-.1.31-.15.43L3.39,10H8.52Z" />
    <path d="M14.13,20V0h.93V20Z" />
    <path d="M18.36,8.48V15.3H18V2H19.6c1.74,0,2.26.92,2.26,2.84V5.4c0,2.1-.54,2.72-1.48,2.94,1,.32,1.38,1.14,1.38,2.56,0,1.16,0,3.64.14,4.4h-.34c-.1-.54-.12-3.4-.14-4.46,0-1.86-.68-2.36-2-2.36Zm0-.3h1.08c1.6,0,2.08-.74,2.08-2.78V4.82c0-1.44-.24-2.54-1.94-2.54H18.36Z" />
    <path d="M24.28,8.48V15.3h-.34V2h1.58c1.74,0,2.26.92,2.26,2.84V5.4c0,2.1-.54,2.72-1.48,2.94,1,.32,1.38,1.14,1.38,2.56,0,1.16,0,3.64.14,4.4h-.34c-.1-.54-.12-3.4-.14-4.46,0-1.86-.68-2.36-2-2.36Zm0-.3h1.08c1.6,0,2.08-.74,2.08-2.78V4.82c0-1.44-.24-2.54-1.94-2.54H24.28Z" />
    <path d="M30.28,9.88,29.4,15.3h-.34L31.26,2h.44L34,15.3h-.34l-.9-5.42Zm2.38-.3c-.82-4.86-1.08-6.42-1.18-7.28h0c-.1,1-.56,3.74-1.12,7.28Z" />
    <path d="M35.38,15.3V2h.48c.76,2.56,3.5,12.06,3.68,12.8h0c-.06-2.1,0-3.72,0-5.88V2h.34V15.3h-.48c-.6-2-3.54-12.22-3.7-12.92h0c0,1.6.06,3.78.06,6.22v6.7Z" />
    <path d="M46.14,15.3h-.3c0-.4,0-.62,0-1.56-.26,1.28-1,1.72-1.88,1.72-1.22,0-2-.64-2-3.14V4.82c0-2,.72-3,2.16-3,1.9,0,2,1.74,2,3v.3h-.34V4.78c0-1.46-.3-2.66-1.7-2.66S42.24,3,42.24,4.88v7.38c0,1.74.38,2.9,1.7,2.9,1.56,0,1.88-1.58,1.88-4V8.72H43.74v-.3h2.4Z" />
    <path d="M48.52,2V15.3h-.35V2Z" />
    <path d="M51.26,9.88l-.88,5.42H50L52.24,2h.44L54.94,15.3H54.6l-.9-5.42Zm2.38-.3c-.82-4.86-1.08-6.42-1.18-7.28h0c-.1,1-.56,3.74-1.13,7.28Z" />
    <path d="M56.53,2.28h-2V2h4.3v.3h-2v13h-.35Z" />
    <path d="M63.82,4.82v7.5c0,2-.54,3.16-2.17,3.16-1.4,0-2.16-.8-2.16-3.12V4.84c0-2.44,1-3,2.18-3S63.82,2.36,63.82,4.82Zm-4,.12v7.3c0,1.88.44,2.94,1.82,2.94,1.59,0,1.83-1.36,1.83-2.78V4.92c0-1.76-.44-2.82-1.83-2.82C60.51,2.1,59.83,2.84,59.83,4.94Z" />
    <path d="M66.19,8.48V15.3h-.34V2h1.58c1.74,0,2.26.92,2.26,2.84V5.4c0,2.1-.54,2.72-1.48,2.94,1,.32,1.38,1.14,1.38,2.56,0,1.16,0,3.64.14,4.4H69.4c-.11-.54-.13-3.4-.15-4.46,0-1.86-.68-2.36-2-2.36Zm0-.3h1.08c1.6,0,2.08-.74,2.08-2.78V4.82c0-1.44-.24-2.54-1.94-2.54H66.19Z" />
    <path d="M72.41,20V0h.93V20Z" />
    <path d="M77,15.3V1.3h6.83V2.49H78.25V7.63h5.12V8.8H78.25v5.32h5.84V15.3Z" />
  </svg>
);

export default Arrangiatore;
