import React from "react";

const Prerecorded = ({ width = 100 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54.59 34.57"
    width={width}
  >
    <path d="M34.5,6V9.06a.72.72,0,0,0,.07.32.5.5,0,0,0,.24.17v.22h-2V9.55A.43.43,0,0,0,33,9.37a.55.55,0,0,0,.07-.31V1.16A.6.6,0,0,0,33,.83a.42.42,0,0,0-.24-.17V.44h4.07A3.76,3.76,0,0,1,38,.57a1.79,1.79,0,0,1,.74.48,2.42,2.42,0,0,1,.52.91,4.25,4.25,0,0,1,.19,1.25,3.61,3.61,0,0,1-.36,1.65,1.86,1.86,0,0,1-.81.87A3.15,3.15,0,0,1,36.84,6Zm0-1.54h2.18a1.6,1.6,0,0,0,.91-.19,1,1,0,0,0,.27-.42,1.79,1.79,0,0,0,.1-.62,1.75,1.75,0,0,0-.1-.62,1,1,0,0,0-.27-.42A1.58,1.58,0,0,0,36.68,2H34.5Z" />
    <path d="M42,6v3.1a.61.61,0,0,0,.07.32.45.45,0,0,0,.24.17v.22h-2V9.55a.46.46,0,0,0,.24-.18.65.65,0,0,0,.07-.31V1.16A.7.7,0,0,0,40.5.83a.45.45,0,0,0-.24-.17V.44h4.08a4.59,4.59,0,0,1,1.18.12,1.89,1.89,0,0,1,.77.43,2.15,2.15,0,0,1,.56,1A4.24,4.24,0,0,1,47,3.21c0,1.53-.59,2.42-1.78,2.68l1.27,2.57a5.94,5.94,0,0,0,.49.87.52.52,0,0,0,.38.22v.22H45V9.55c.16,0,.25-.12.25-.25a2.15,2.15,0,0,0-.23-.59L43.73,6Zm0-1.54h2.2a3.81,3.81,0,0,0,.69,0,.85.85,0,0,0,.36-.17,1.19,1.19,0,0,0,.37-1,1.17,1.17,0,0,0-.37-1A.87.87,0,0,0,44.88,2a5.29,5.29,0,0,0-.69,0H42Z" />
    <path d="M49.9,5.67V8.23h4.2a.26.26,0,0,0,.22-.1.76.76,0,0,0,.12-.34h.15v2.42h-.15a.87.87,0,0,0-.12-.35.28.28,0,0,0-.22-.09H48.17V9.55a.43.43,0,0,0,.24-.18.56.56,0,0,0,.07-.32V1.16a.6.6,0,0,0-.07-.33.42.42,0,0,0-.24-.17V.44H54a.25.25,0,0,0,.22-.1A1,1,0,0,0,54.33,0h.15V2.41h-.15a1,1,0,0,0-.13-.34A.27.27,0,0,0,54,2H49.9V4.19h2.65a.26.26,0,0,0,.22-.1.76.76,0,0,0,.12-.34h.16V6.11h-.16a.87.87,0,0,0-.12-.34.29.29,0,0,0-.22-.1Z" />
    <path d="M1.73,17v3.1a.58.58,0,0,0,.07.32.42.42,0,0,0,.24.17v.22H0v-.22a.45.45,0,0,0,.24-.18.62.62,0,0,0,.07-.31v-7.9a.66.66,0,0,0-.07-.32A.45.45,0,0,0,0,11.73v-.22H4.08a4.59,4.59,0,0,1,1.18.12,1.79,1.79,0,0,1,.76.43,2.16,2.16,0,0,1,.57,1,4.26,4.26,0,0,1,.18,1.26c0,1.52-.6,2.41-1.78,2.67l1.27,2.58a5.94,5.94,0,0,0,.49.87.51.51,0,0,0,.38.21v.22H4.77v-.22c.16,0,.25-.12.25-.24a2.16,2.16,0,0,0-.23-.6L3.47,17Zm0-1.53h2.2a3.59,3.59,0,0,0,.69-.05A.75.75,0,0,0,5,15.28a1.17,1.17,0,0,0,.37-1,1.19,1.19,0,0,0-.37-1,.87.87,0,0,0-.36-.18,5.29,5.29,0,0,0-.69,0H1.73Z" />
    <path d="M9.64,16.75V19.3h4.2a.28.28,0,0,0,.22-.09.87.87,0,0,0,.12-.34h.15v2.41h-.15a.76.76,0,0,0-.12-.34.26.26,0,0,0-.22-.1H7.91v-.22a.39.39,0,0,0,.24-.18.56.56,0,0,0,.07-.32V12.23a.56.56,0,0,0-.07-.32.39.39,0,0,0-.24-.18v-.22h5.81a.25.25,0,0,0,.22-.1,1,1,0,0,0,.13-.34h.15v2.42h-.15a.88.88,0,0,0-.13-.34.25.25,0,0,0-.22-.1H9.64v2.21h2.65a.28.28,0,0,0,.22-.09.87.87,0,0,0,.12-.34h.16v2.35h-.16a.87.87,0,0,0-.12-.34.28.28,0,0,0-.22-.09Z" />
    <path d="M21.67,17.63l.91,1.95-.13.12c-.09-.12-.16-.18-.22-.18a1.45,1.45,0,0,0-.48.33,6,6,0,0,1-1.38.92A3.42,3.42,0,0,1,19,21a3.31,3.31,0,0,1-2.53-1,4.31,4.31,0,0,1-.92-1.51,6.42,6.42,0,0,1-.39-2.28,5.87,5.87,0,0,1,.7-2.94A3.36,3.36,0,0,1,19,11.35a3.32,3.32,0,0,1,1.32.23,6.16,6.16,0,0,1,1.39.91,1.45,1.45,0,0,0,.48.33c.06,0,.13-.06.22-.18l.13.12-.91,2-.13-.12c0-.08,0-.14,0-.19a.65.65,0,0,0-.12-.37,2.45,2.45,0,0,0-.43-.42,2.82,2.82,0,0,0-1.79-.68,2.21,2.21,0,0,0-1.78.8,3.68,3.68,0,0,0-.78,2.44,3.69,3.69,0,0,0,.78,2.45,2.21,2.21,0,0,0,1.78.8A2.82,2.82,0,0,0,21,18.74a2.45,2.45,0,0,0,.43-.42.68.68,0,0,0,.12-.38c0-.05,0-.11,0-.18Z" />
    <path d="M27,11.35a3.22,3.22,0,0,1,2.71,1.32,5.73,5.73,0,0,1,1.06,3.56,6.19,6.19,0,0,1-.51,2.51A3.35,3.35,0,0,1,27,21a3.2,3.2,0,0,1-2.69-1.31A5.1,5.1,0,0,1,23.46,18a6.85,6.85,0,0,1-.24-1.83,6.29,6.29,0,0,1,.5-2.56A3.37,3.37,0,0,1,27,11.35Zm0,1.58a1.94,1.94,0,0,0-1.56.79,3.88,3.88,0,0,0-.73,2.45,3.87,3.87,0,0,0,.73,2.46,1.92,1.92,0,0,0,1.57.79,1.89,1.89,0,0,0,1.56-.79,3.48,3.48,0,0,0,.56-1.16,4.34,4.34,0,0,0,.17-1.25,3.88,3.88,0,0,0-.73-2.5A2,2,0,0,0,27,12.93Z" />
    <path d="M33.47,17v3.1a.58.58,0,0,0,.07.32.42.42,0,0,0,.23.17v.22h-2v-.22a.45.45,0,0,0,.24-.18.62.62,0,0,0,.07-.31v-7.9a.66.66,0,0,0-.07-.32.45.45,0,0,0-.24-.18v-.22h4.08a4.59,4.59,0,0,1,1.18.12,1.79,1.79,0,0,1,.76.43,2.16,2.16,0,0,1,.57,1,4.27,4.27,0,0,1,.17,1.26q0,2.28-1.77,2.67L38,19.54a5.1,5.1,0,0,0,.49.87.51.51,0,0,0,.38.21v.22H36.51v-.22c.16,0,.25-.12.25-.24a2.16,2.16,0,0,0-.23-.6L35.21,17Zm0-1.53h2.2a3.39,3.39,0,0,0,.68-.05.74.74,0,0,0,.37-.17,1.2,1.2,0,0,0,.37-1,1.22,1.22,0,0,0-.37-1,.87.87,0,0,0-.36-.18,5.29,5.29,0,0,0-.69,0h-2.2Z" />
    <path d="M40,20.12V12.23a.56.56,0,0,0-.07-.32.39.39,0,0,0-.24-.18v-.22h3.64a3.57,3.57,0,0,1,1.84.39,3.33,3.33,0,0,1,1.35,1.87,7.31,7.31,0,0,1,.35,2.4,5.68,5.68,0,0,1-1,3.68,2.41,2.41,0,0,1-1,.76,4.2,4.2,0,0,1-1.49.23H39.65v-.22a.39.39,0,0,0,.24-.18A.56.56,0,0,0,40,20.12Zm1.42-.82h1.79A2.12,2.12,0,0,0,44.46,19a3.43,3.43,0,0,0,.92-2.8,4.39,4.39,0,0,0-.5-2.3,1.55,1.55,0,0,0-.67-.63,2.36,2.36,0,0,0-1-.19H41.38Z" />
    <path d="M19.05,30v2.56h4.2a.26.26,0,0,0,.22-.1.76.76,0,0,0,.12-.34h.16v2.42h-.16a.87.87,0,0,0-.12-.35.28.28,0,0,0-.22-.09H17.32v-.22a.47.47,0,0,0,.24-.18.56.56,0,0,0,.07-.32v-7.9a.58.58,0,0,0-.07-.32.42.42,0,0,0-.24-.17V24.8h5.81a.27.27,0,0,0,.22-.1.73.73,0,0,0,.13-.34h.15v2.41h-.15a.73.73,0,0,0-.13-.34.27.27,0,0,0-.22-.1H19.05v2.22H21.7a.25.25,0,0,0,.22-.1.76.76,0,0,0,.12-.34h.16v2.36H22a.87.87,0,0,0-.12-.34.27.27,0,0,0-.22-.1Z" />
    <path d="M25.06,33.41v-7.9a.7.7,0,0,0-.06-.32.5.5,0,0,0-.24-.17V24.8H28.4a3.53,3.53,0,0,1,1.83.39,3.27,3.27,0,0,1,1.36,1.87,7.5,7.5,0,0,1,.35,2.4,5.7,5.7,0,0,1-1,3.68,2.49,2.49,0,0,1-1,.76,4.1,4.1,0,0,1-1.48.23H24.76v-.22a.58.58,0,0,0,.24-.18A.67.67,0,0,0,25.06,33.41Zm1.43-.82h1.79a2,2,0,0,0,1.28-.34,3.37,3.37,0,0,0,.93-2.79,4.39,4.39,0,0,0-.5-2.3,1.64,1.64,0,0,0-.67-.64,2.51,2.51,0,0,0-1-.19H26.49Z" />
  </svg>
);

export default Prerecorded;
