import React from "react";

const Tiktok = ({ width = 30 }) => (
  <svg viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg" width={width}>
    <path d="m240 80v-16h-32v176c0 26.472656-21.527344 48-48 48s-48-21.527344-48-48 21.527344-48 48-48h16v-32h-16c-44.113281 0-80 35.886719-80 80s35.886719 80 80 80 80-35.886719 80-80v-96.152344c13.390625 10.089844 29.976562 16.152344 48 16.152344h16v-32h-16c-26.472656 0-48-21.527344-48-48zm0 0" />
    <path d="m0 384h384v-384h-384zm32-352h320v320h-320zm0 0" />
  </svg>
);

export default Tiktok;
