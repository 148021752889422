import React from "react";

const Orphonic = ({ width = 200 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 59.65 14.48"
    width={width}
  >
    <path d="M0,8.39C0,5.2,1.81,2.62,4,2.62s4,2.58,4,5.77-1.81,5.77-4,5.77S0,11.57,0,8.39Zm.62,0c0,2.69,1.53,4.87,3.41,4.87s3.41-2.18,3.41-4.87S5.91,3.5,4,3.5.62,5.69.62,8.39ZM2.85,8.1c0-.93.52-1.69,1.18-1.69s1.18.76,1.18,1.69S4.69,9.79,4,9.79,2.85,9,2.85,8.1Zm.62,0c0,.44.25.81.56.81s.57-.37.57-.81S4.34,7.3,4,7.3,3.47,7.66,3.47,8.1Z" />
    <path d="M10.68,9.72v4.55h-.61v-10h3.08c1.34,0,2.31.83,2.31,2.63,0,1.17-.36,2.07-1.3,2.4.55.19,1.12.75,1.12,2.47v.41a14.71,14.71,0,0,0,.08,2.08h-.62a12,12,0,0,1-.07-2v-.34c0-1.72-.48-2.21-1.81-2.21Zm0-.8H13c1.23,0,1.86-.62,1.86-2A1.62,1.62,0,0,0,13,5.08H10.68Z" />
    <path d="M17.35,4.28h3.1c1.79,0,2.43,1.34,2.43,2.83,0,1.92-.9,3-2.37,3H18v4.21h-.61Zm.61,5h2.41c1.09,0,1.87-.44,1.87-2.12,0-1.3-.63-2-1.87-2H18Z" />
    <path d="M24.43,4.28H25V8.47h4.73V4.28h.61v10h-.61v-5H25v5h-.61Z" />
    <path d="M32.39,8.39c0-3.19,1.81-5.77,4-5.77s4,2.58,4,5.77-1.81,5.77-4,5.77S32.39,11.57,32.39,8.39Zm.61,0c0,2.69,1.54,4.87,3.42,4.87s3.41-2.18,3.41-4.87S38.29,3.5,36.42,3.5,33,5.69,33,8.39Zm2.23-.29c0-.93.53-1.69,1.19-1.69s1.18.76,1.18,1.69-.53,1.69-1.18,1.69S35.23,9,35.23,8.1Zm.63,0c0,.44.24.81.56.81S37,8.54,37,8.1s-.25-.8-.56-.8S35.86,7.66,35.86,8.1Z" />
    <path d="M42.45,14.27v-10h.74c1.37,2.51,4.39,8,4.76,8.92h0c-.06-1.44,0-2.86,0-4.47V4.28h.59v10H47.8C46.56,12,43.56,6.39,43,5.22h0C43,6.53,43,8,43,9.71v4.56Z" />
    <path d="M51.27,4.28v10h-.61v-10Z" />
    <path d="M59.62,11.48c-.44,1.69-1.26,3-3.15,3C54.25,14.48,53,12.24,53,9.3s1.43-5.24,3.54-5.24c2,0,2.84,1.48,3.15,3.11H59c-.39-1.3-1-2.3-2.52-2.3-1.76,0-2.88,1.84-2.88,4.4s1.14,4.41,2.89,4.41c1.49,0,2.06-.95,2.5-2.2Z" />
    <line
      className="a"
      x1="0.99"
      y1="0.25"
      x2="59.53"
      y2="0.25"
      fill={"none"}
      stroke={"#000"}
      strokeMiterlimit={10}
      strokeWidth={"0.5px"}
    />
    <line
      className="a"
      x1="59.28"
      y1="7.07"
      x2="59.28"
      y2="0.25"
      fill={"none"}
      stroke={"#000"}
      strokeMiterlimit={10}
      strokeWidth={"0.5px"}
    />
  </svg>
);

export default Orphonic;
