import React from "react";

const UserFilled = ({ width = 42 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" width={width}>
    <path
      d="M-1951.281-2832H-1990a20.865,20.865,0,0,1,6.151-14.85,20.926,20.926,0,0,1,7.98-5,12.136,12.136,0,0,1-5.271-10A12.154,12.154,0,0,1-1969-2874a12.155,12.155,0,0,1,12.141,12.141,12.137,12.137,0,0,1-5.271,10,20.925,20.925,0,0,1,7.98,5A20.865,20.865,0,0,1-1948-2832ZM-1969-2853h0Z"
      transform="translate(1990 2874)"
    />
  </svg>
);

export default UserFilled;
